define('frontend/authenticators/devise-related-person', ['exports', 'ember-simple-auth/authenticators/devise'], function (exports, _devise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _devise.default.extend({
    tokenAttributeName: 'authentication_token',
    identificationAttributeName: 'username',
    serverTokenEndpoint: '/related_people/sign_in',

    authenticate: function authenticate(params) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var useResponse = _this.get('rejectWithResponse');
        var data = {};

        var _getProperties = _this.getProperties('identificationAttributeName', 'tokenAttributeName'),
            identificationAttributeName = _getProperties.identificationAttributeName,
            tokenAttributeName = _getProperties.tokenAttributeName;

        data['related_person'] = params;

        _this.makeRequest(data).then(function (response) {
          if (response.ok) {
            response.json().then(function (json) {
              if (_this._validate(json)) {
                var resourceName = _this.get('resourceName');
                var _json = json[resourceName] ? json[resourceName] : json;
                Ember.run(null, resolve, _json);
              } else {
                Ember.run(null, reject, 'Check that server response includes ' + tokenAttributeName + ' and ' + identificationAttributeName);
              }
            });
          } else {
            if (useResponse) {
              Ember.run(null, reject, response);
            } else {
              response.json().then(function (json) {
                return Ember.run(null, reject, json);
              });
            }
          }
        }).catch(function (error) {
          return Ember.run(null, reject, error);
        });
      });
    }
  });
});