define('frontend/models/provider-stat', ['exports', 'ember-data', 'frontend/models/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    stats: _emberData.default.attr("object"),
    provider: _emberData.default.belongsTo('provider', { async: true })
  });
});