define("frontend/locales/en/model", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    patient: {
      label: "Client",
      firstName: "First Name",
      lastName: "Last Name",
      preferredFirstName: "First Name (Preferred)",
      preferredLastName: "Last Name (Preferred)",
      preferredPronouns: "Preferred Pronouns",
      legalFirstName: "First Name (Legal)",
      legalName: "Full Name (Legal)",
      legalLastName: "Last Name (Legal)",
      preferredName: "Preferred Name",
      gender: "Sex",
      genderIdentity: "Gender Identity (free text)",
      genderIdentityFhir: "Gender Identity (select)",
      genderIdentityCombined: "Gender Identity",
      email: "Email",
      phoneNumber: "Phone Number",
      race: "Race",
      educationYears: "Education (years)",
      researchPrimaryDiagnosis: "Primary Diagnosis",
      researchOtherDiagnoses: "Other Diagnosis",
      researchOther: "Other Details",
      dob: "Date of Birth",
      dobShort: "Date of Birth",
      yob: "Year of Birth",
      lastSsn: "Last 4 SSN",
      isTest: "Test Client",
      relatedPeople: "Related people",
      language: "Default Language",
      flags: "Flags",
      primaryProvider: "Primary Clinician",
      timezone: "Timezone",
      currentStats: {
        updatedAt: "Updated",
        complexity: "Complexity",
        severity: "Severity",
        severityTrend: {
          label: "Trend",
          icon: {
            increasing: "fa-arrow-up",
            decreasing: "fa-arrow-down",
            stable: "fa-arrows-h",
            none: ""
          }
        },
        complexityDescription: "Complexity indicates the total number of symptoms endorsed, capped at 10",
        severityDescription: "Severity indicates the magnitude of the client's most severe symptom on a 1-10 scale",
        severityTrendDescription: "Trend indicates the change in severity since the previous session"
      },
      errors: {
        "mrn - has already been taken": "A client with this MRN has already been created. Please check the client does not already exist, or enter a different MRN."
      }
    },
    invitation: {
      preliminary: "Processing",
      dateEmailed: "Emailed",
      dateTexted: "Text message sent",
      dateBegun: "Begun",
      dateCompleted: "Completed",
      dateCreated: "Created",
      lastNotificationSent: "Notification sent",
      status: "Status",
      sendEmailAt: "Email will be sent",
      sendEmailAtShort: "will send",
      sendTextAt: "Text Message will be sent",
      sendTextAtShort: "will send",
      statuses: {
        icon: {
          future: "fa-calendar-o",
          new: "",
          sent: "fa-send-o",
          complete: "fa-check-circle",
          partial: "fa-check-circle-o",
          locked_out: "fa-lock",
          begun: "fa-hourglass-half"
        },
        future: "Future",
        new: "",
        locked_out: "Client Locked Out",
        sent: "Sent to client",
        complete: "Completed",
        partial: "Partially Completed",
        begun: "Begun",
        opened: "Opened"
      },
      statusSummary: {
        future: "Measurement not yet available for completion",
        begun: "Measurement already in progress",
        complete: "Measurement already completed"
      },
      phases: {
        before: "Before session",
        during: "During session",
        after: "After session"
      },
      phaseDescription: {
        before: "Administered before session",
        during: "Administered during session",
        after: "Administered after session"
      },
      formats: {
        interview: "Interview format",
        self_report: "Self-report format"
      },
      scales: "Scales Included",
      inviteEmailPrefix: "Send email to {email}",
      inviteTextPrefix: "Send text message to {phoneNumber}",
      inviteHoursBefore: {
        suffix: "prior to appointment",
        2: "2 hours",
        4: "4 hours",
        12: "12 hours",
        24: "24 hours",
        48: "48 hours"
      },
      inviteHoursAfter: {
        suffix: "after appointment",
        2: "2 hours",
        4: "4 hours",
        12: "12 hours",
        24: "24 hours",
        48: "48 hours"
      }
    },
    participant: {
      participantType: {
        provider: "Provider",
        patient: "Client",
        guardian: "Parent/Caregiver",
        teacher: "Teacher",
        other: "Other",
        father: "Father",
        mother: "Mother"
      }
    },
    patientSession: {
      status: "Status",
      statuses: {
        icon: {
          new: "",
          awaiting_assessment: "fa-send-o",
          scheduled: "fa-calendar-o",
          complete: "fa-check-circle",
          assessment_in_progress: "fa-pencil-square-o",
          report_previewed: "fa-cogs",
          report_viewed: "fa-check-circle",
          feedback_available: "fa-check-circle",
          feedback_complete: "fa-check-circle",
          canceled: "fa-close",
          closed: "fa-close",
          not_measured: "fa-close",
          noshow: "fa-calendar-times-o"
        },
        new: "New",
        appointment_created: "Appointment Created",
        awaiting_assessment: "Waiting for Response",
        assessment_in_progress: "Measurement In Progress",
        feedback_available: "Response Available",
        feedback_complete: "Response Complete",
        report_previewed: "Report Previewed",
        report_viewed: "Report Viewed",
        scheduled: "Scheduled",
        complete: "Complete",
        canceled: "Canceled",
        closed: "Closed",
        not_measured: "Not Measured",
        noshow: "No Show"
      },
      shortStatuses: {
        new: "New",
        awaiting_assessment: "Not Taken",
        assessment_in_progress: "In Progress",
        feedback_available: "Partial",
        feedback_complete: "Ready",
        report_previewed: "Previewed",
        report_viewed: "Viewed",
        scheduled: "Scheduled",
        complete: "Complete",
        canceled: "Canceled",
        closed: "Missed",
        not_measured: "Not Measured",
        noshow: "No Show"
      },
      defaultName: "{dateString} ({statusString})",
      report: "Report",
      effectiveStart: "Date",
      targetDate: "Date",
      statusUpdatedAt: "Last Changed",
      careEpisode: "Episode of Care",
      autoplanWarning: "The scales for this session are calculated automatically.",
      autoplanExplanation: "They may change automatically if the plans change, or other assessments are changed or canceled.",
      needsReplanning: "Awaiting replanning",
      needsReplanningDescription: "This session is awaiting replanning by the measurement system. The administered scales may change when replanned."
    },
    provider: {
      organizations: "Units",
      defaultRelationship: "Staff role",
      accessFrequency: "Access Frequency",
      accessFrequencies: {
        frequent: "Frequent",
        rare: "Rare"
      },
      npiNumber: "NPI Number",
      usernamePlaceholder: "Leave blank to use email",
      practicing: "Practicing",
      practicingLong: "Practicing (can assign to patients)",
      errors: {
        "user.username - must match email": "A client or other user with this email address has already been created. Please contact support to gain help creating this user",
        "provider-organizations - can't be blank": "You must add this provider to at least one unit"
      },
      enabled: "Enabled",
      status: "Current Status",
      statusCode: {
        active: "Active",
        pending: "Pending enablement",
        disabled_manually: "Disabled manually",
        disabled_inactivity: "Suspended for inactivity",
        disabled_ehr: "Disabled by EMR",
        awaiting_first_login: "Pending first login"
      },
      roles: "Roles"
    },
    relatedPerson: {
      name: "Name (Relationship)",
      firstName: "First Name",
      lastName: "Last Name",
      relationship: "Relationship",
      active: "Active (will receive measurements)",
      inactive: "Inactive",
      language: "Default Language"
    },
    appointment: {
      label: "Appointment",
      status: "Status",
      statuses: {
        icon: {
          new: "",
          booked: "fa-calendar",
          fulfilled: "fa-check-circle",
          no_show: "fa-close",
          cancelled: "fa-ban"
        },
        new: "",
        booked: "Scheduled",
        fulfilled: "Completed",
        no_show: "No-show",
        canceled: "Canceled"
      }
    },
    gender: {
      M: "male",
      F: "female",
      O: "other"
    },
    genderIdentity: {
      male: "male",
      female: "female",
      non_binary: "non-binary",
      transgender_male: "transgender male",
      transgender_female: "transgender female",
      non_disclose: "did not disclose",
      other: "other"
    },
    institute: {
      name: "Name",
      shortcode: "Shortcode (Domain)",
      timezone: "Timezone",
      hasSupportAccounts: "Create support accounts",
      hasKioskAccount: "Create kiosk account",
      createMeasurementPlans: "Create measurement plans",
      patientAuthenticationMethod: "Patient Authentication",
      isTest: "Is test",
      enabled: "Is enabled"
    },
    patientUploadProcess: {
      patientFile: {
        label: "Client List",
        description: "Comma-separated list of clients to upload"
      },
      defaultDoctor: {
        label: "Default clinician",
        description: "If a clinicianId column is not provided, this is the clinician that will be assigned to the clients"
      },
      submit: "Upload and create"
    },
    event: {
      eventDate: "Date",
      provider: "Provider",
      patient: "Client",
      eventName: "Event",
      eventNames: {
        provider: {
          login: "Login",
          reportView: "Report Viewed",
          createdAssessment: "Created measurement"
        },
        patient: {
          emailedAssessment: "Emailed measurement",
          failedLogin: "Failed Client Login",
          beganAssessment: "Began measurement",
          assessmentTimeOut: "Timed out taking measurement",
          assessmentExit: "Exited measurement",
          created: "Client Created",
          reportComplete: "Report Complete",
          completedAssessment: "Completed measurement"
        }
      }
    },
    configurationProperty: {
      code: "Code",
      name: "Setting",
      description: "Description",
      category: "Category",
      defaultValue: "Default value"
    },
    configurationValue: {
      author: "Author",
      notes: "Notes",
      value: "Value"
    },
    careEpisode: {
      periodStart: "Period Start",
      periodEnd: "Period End",
      status: "Status",
      name: "Name",
      statuses: {
        planned: "Planned",
        waitlist: "Wait List",
        active: "Active",
        on_hold: "On Hold",
        finished: "Finished",
        canceled: "Canceled"
      },
      providers: "Providers",
      careTeam: "Care Team",
      organization: "Organization",
      summary: "{status} {periodStart} - {periodEnd}. {note}",
      summaryNoEndDate: "{status} started {periodStart}. {note}"
    },
    careEpisodeProvider: {
      relationships: {
        psychiatrist: "Psychiatrist",
        psychologist: "Psychologist",
        therapist: "Therapist",
        social_worker: "Social Worker",
        research_assistant: "Research Assistant",
        counselor: "Counselor",
        administrator: "Administrator",
        director: "Director",
        clinician: "Clinician",
        case_manager: "Case Manager",
        front_desk: "Front Desk",
        executive: "Executive",
        care_manager: "Care Manager",
        primary_care_physician: "Primary Care Physician"
      }
    },
    user: {
      firstName: "First Name",
      lastName: "Last Name",
      username: "Username",
      ssoUsername: "SSO Username",
      email: "Email",
      phoneNumber: "Phone Number",
      title: "Title",
      titlePlaceholder: "e.g. Dr",
      suffix: "Suffix",
      suffixPlaceholder: "e.g. M.D",
      roles: "Roles",
      errors: {
        "username - has already been taken": "That username has already been taken"
      }
    },
    role: {
      clinician: {
        name: "Clinician"
      },
      executive: {
        name: "Executive"
      },
      superuser: {
        name: "Superuser"
      }
    },
    dataDump: {
      statuses: {
        new: "Awaiting Processing",
        processing: "Processing",
        ready: "Ready",
        expired: "Expired",
        error: "Error"
      },
      resources: {
        answers: "Answers",
        appointments: "Appointments",
        diagnosticReports: "Diagnostic Reports",
        encounters: "Encounters",
        episodesOfCare: "Episodes Of Care",
        observations: "Observations",
        organizations: "Organizational Units",
        patients: "Clients",
        practitioners: "Practitioners",
        questions: "Questions",
        questionnaires: "Questionnaires",
        questionnaireResponses: "Questionnaire Responses",
        relatedPeople: "Related People",
        reimbursementList: "Reimbursement List",
        researchSet: "Research Data Set (questionnaire-based)",
        anonResearchSet: "Anonymized Research Data Set (questionnaire-based)",
        researchPatientSet: "Research Data Set (patient-based)",
        researchExperiment: "Research Experiment Data Set",
        userConsent: "User Consents",
        "Pipbhc::PipbhcEpisodeOfCare": "PIPBHC Episodes of Care",
        "Pipbhc::PipbhcPatient": "PIPBHC Patients",
        "Pipbhc::PipbhcObservation": "PIPBHC Observations",
        "Pipbhc::PipbhcReferral": "PIPBHC Referrals",
        "Pipbhc::PipbhcCondition": "PIPBHC Conditions",
        contactDetailsQuality: "Contact Details Quality",
        reportView: "Provider Feedback Report Viewing"
      },
      name: "Name",
      namePlaceholder: "E.g. March 14th Export"
    },
    userConsent: {
      respondedAt: "Response Time",
      consentType: "Type of Consent",
      acceptance: "Accepted"
    },
    patientFlag: {
      icon: "Icon",
      name: "Name",
      description: "Description",
      details: "Details",
      clear: "Clear",
      notes: "Notes",
      manualSet: "Set"
    },
    goal: {
      patientText: "Goal/Problem",
      patientTextPlaceholder: "e.g. Control my breathing during panic attacks",
      goalType: "Item Type",
      participant: "Respondent"
    },
    careEpisodeCondition: {
      onsetDate: "Onset Date",
      abatementDate: "Onset Date",
      status: "Status",
      explanation: "This information has been imported from your EMR. If you want to update this information, please do so in your EMR directly"
    },
    rawScaleDatum: {
      mnemonic: "Mnemonic",
      scaleData: "Scale Data",
      dependencies: "Dependencies"
    },
    scale: {
      name: "Name",
      category: "Category",
      estimatedTime: "Estimated Time",
      estimatedTimeShort: "Est. Time (m)",
      estimatedTimeNano: "Time",
      age: "Age",
      languages: "Languages",
      participants: "Participants",
      adminInterval: "Admin Interval",
      tags: "Tags",
      gender: "Sex"
    },
    reportScale: {
      manual: "User",
      automatic: "Algorithm",
      planningDecisions: {
        administer: "Administer",
        onset_session: "Before Onset Session",
        onset_time: "Before Onset Time",
        no_repeat: "Does not repeat",
        repeat: "Too close to previous administration session",
        max_repeat_interval: "Too close to previous administration time",
        discontinue_session: "After Discontinue Session",
        discontinue_time: "After Discontinue Time",
        alternation: "Not selected from alternation group",
        no_longer_in_plan: "No longer in plan"
      }
    },
    entity: {
      path: "Path",
      collections: {
        institutes: "Organizations",
        organizations: "Units",
        providers: "Providers",
        patients: "Patients",
        "care-episodes": "Care Episodes",
        "patient-sessions": "Sessions",
        payors: "Insurance Provider",
        experiments: "Experiments",
        "experiment-variants": "Experimental Groups",
        conditions: "Conditions",
        users: "Users",
        "treatment-tracks": "Treatment Tracks",
        "institute-payors": "Insurance Company",
        "institute-conditions": "Diagnosis",
        "treatment-services": "Treatment Services",
        "entity-decision-algorithms": "Decision Support Algorithms",
        workflows: "Decision Support Workflows",
        "workflow-actions": "Decision Support Actions",
        panels: "Panels"
      }
    },
    entityPath: {
      path: "Path"
    },
    measurementPlan: {
      title: "Protocol",
      name: "Name",
      namePlaceholder: "E.g. Outpatient Adult Protocol",
      description: "Description",
      descriptionPlaceholder: "(Optional) E.g. a short description of the features of the protocol",
      shared: "Make this plan available for others to use",
      planType: {
        core: "Core",
        supplemental: "Supplemental",
        compliance: "Compliance"
      },
      planTypeDescription: {
        core: "Each measurement has only one core plan.",
        supplemental: "All supplemental plans are administered.",
        compliance: "All compliance plans are administered and cannot be disabled."
      }
    },
    entityMeasurementPlan: {
      demographics: "Demographics",
      filters: "Filters",
      planType: "Type",
      startDate: "Start Date",
      startDatePlaceholder: "Start immediately",
      endDate: "End Date",
      endDatePlaceholder: "Until further notice",
      dateSummary: {
        both: "{startDate} - {endDate}",
        starting: "Starting {startDate}",
        ending: "Ending {endDate}",
        neither: "Starting now, until further notice"
      },
      ageSummary: {
        minMax: "{minAge}-{maxAge} years old",
        min: "{minAge} years or older",
        max: "{maxAge} years or younger",
        all: "all ages"
      },
      active: "Active",
      activeExplanation: "<b>Active</b>. Measurement plan will be used to choose scales",
      inactive: "Disabled",
      inactiveExplanation: "<b>Disabled</b>. Measurement plan will <b>not</b> be used to choose scales. If the same protocol is used at higher level, it will be disabled here",
      needsReplanning: "Changes recently made to this measurement plan have not been fully updated yet"
    },
    measurementPlanScale: {
      onsetSession: "Onset session #",
      onsetSessionPlaceholder: "After how many sessions to begin assessing, e.g. 3",
      onsetTime: "Onset time",
      onsetTimePlaceholder: "How long after the first session to begin assessing, e.g. 2 weeks",
      repeatSessions: "Repeat every X sessions",
      repeatSessionsPlaceholder: "Use the measurement e.g every 3 sessions",
      maxRepeatInterval: "Repeat at most once per",
      maxRepeatIntervalPlaceholder: "Do not use the measurement more than once per e.g. 4 weeks",
      schedulePreset: "Frequency",
      schedulePresetChoose: "Choose Measure Frequency",
      discontinueSession: "Discontinue session #",
      discontinueSessionPlaceholder: "After how many sessions to stop assessing, e.g. 3",
      discontinueTime: "Discontinue time",
      discontinueTimePlaceholder: "How long after the first session to stop assessing, e.g. 2 weeks",
      phase: "Administration phase",
      defaultPhase: "Scale default",
      defaultPhaseNamed: "Scale default ({phase})",
      format: "Administration format",
      defaultFormat: "Scale default",
      defaultFormatNamed: "Scale default ({format})",
      blinding: "Research Blinding",
      blindedExplanation: "This measure will be hidden from reports as part of a research study",
      conditionalLogic: "Conditional Administration Logic",
      conditionalLogicPlaceholder: "Advanced configuration - contact support to configure",
      conditionalLogicExplanation: "This measure has conditional administration logic and will only be administered if its trigger is met",
      displayOrder: "Display Order",
      displayOrderPlaceholder: "What order to display this scale. Leave blank for default. Lower numbers administered first"
    },
    alterMeasurementPlanScaleRequest: {
      errors: {
        "scales-to-remove - No scales can be removed": "You do not have permission to remove these scale(s) as they are part of an organization default plan"
      }
    },
    payor: {
      searchBy: "Search by name, EDI code...",
      name: "Name",
      primaryId: "Code",
      contracts: "Contracts",
      alternateNames: "Alternate names",
      model: "Model",
      models: {
        bcbs: "Blue Cross/Blue Shield",
        commercial: "Commercial",
        medicaid: "Medicaid",
        medicare: "Medicare"
      }
    },
    institutePayor: {
      payor: "Payer",
      primaryId: "Primary Id"
    },
    payorContract: {
      name: "Name",
      startDate: "Start Date",
      endDate: "End Date",
      payor: "Payer"
    },
    coverage: {
      payor: "Payer",
      dates: "Active Dates",
      subscriberNumber: "Subscriber Number",
      institutePayor: "Insurance Company",
      isPrimary: "Is Primary (will be billed)",
      isPrimaryColumn: "Primary?",
      relationships: {
        self: "Self",
        child: "Child",
        parent: "Parent",
        spouse: "Spouse",
        common: "Common",
        injured: "Injured",
        other: "Other",
        none: "None"
      },
      relationship: "Relation to subscriber",
      errors: {}
    },
    chargeItemCode: {
      code: "Code"
    },
    payorContractCode: {
      usageDescription: "Usage",
      contractedUnitPrice: "Contracted Amount",
      listUnitPrice: "List Amount",
      contractLimits: "Contract Limits"
    },
    chargeItem: {
      eligibilityStatus: "Eligibility",
      status: "Status",
      statuses: {
        planned: "Planned",
        billable: "Billable",
        not_billable: "Not billable",
        entered_in_error: "Entered in error",
        unknown: "Unknown",
        billed: "Submitted",
        aborted: "Aborted"
      },
      eligibilityStatuses: {
        planned: "Planned",
        billable: "Billable",
        not_billable: "Not billable",
        entered_in_error: "Entered in error",
        unknown: "Unknown",
        missed: "Not completed",
        canceled: "Canceled"
      },
      dataChanged: "Awaiting processing",
      algorithmState: "Algorithm decision",
      algorithmStates: {
        assessment_completed_session_fulfilled: {
          title: "Assessment completed, appointment fulfilled"
        },
        assessment_completed_first_session_missed: {
          title: "Assessment completed, first appointment, appointment missed"
        },
        assessment_completed_session_missed: {
          title: "Assessment completed, appointment missed"
        },
        assessment_completed_session_outstanding: {
          title: "Assessment completed, appointment outstanding"
        },
        assessment_completed_session_ignored: {
          title: "Assessment completed"
        },
        assessment_begun_session_fulfilled: {
          title: "Assessment begun, appointment fulfilled"
        },
        assessment_begun_first_session_missed: {
          title: "Assessment begun, first appointment, appointment missed"
        },
        assessment_begun_session_missed: {
          title: "Assessment begun, appointment missed"
        },
        assessment_begun_session_outstanding: {
          title: "Assessment begun, appointment outstanding"
        },
        assessment_begun_session_ignored: {
          title: "Assessment begun"
        },
        assessment_missed_session_fulfilled: {
          title: "Assessment missed, appointment fulfilled"
        },
        assessment_missed_first_session_missed: {
          title: "Assessment missed, first appointment, appointment missed"
        },
        assessment_missed_session_missed: {
          title: "Assessment missed, appointment missed"
        },
        assessment_missed_session_outstanding: {
          title: "Assessment missed, appointment outstanding"
        },
        assessment_missed_session_ignored: {
          title: "Assessment missed"
        },
        assessment_planned_session_fulfilled: {
          title: "Assessment outstanding, appointment fulfilled"
        },
        assessment_planned_session_outstanding: {
          title: "Assessment outstanding, appointment outstanding"
        },
        assessment_planned_session_missed: {
          title: "Assessment outstanding, appointment missed"
        },
        assessment_planned_first_session_missed: {
          title: "Assessment outstanding, first appointment, appointment missed"
        },
        assessment_planned_first_session_ignored: {
          title: "Assessment outstanding"
        }
      },
      scale: "Scale",
      participant: "Participant",
      quantity: "Units"
    },
    charge: {
      serviceDate: "DoS",
      providerName: "Provider",
      organizationName: "Facility",
      expectedUnits: "Units",
      expectedAmount: "Expected $",
      receivedUnits: "Received Units",
      receivedAmount: "Received $",
      plannedUnits: "Planned Units",
      plannedAmount: "Planned $",
      billableUnits: "Units",
      billableAmount: "Billable $",
      submittedAt: "Submitted at",
      receivedDate: "Received at",
      insurancePlan: "Insurance",
      status: "Status",
      statuses: {
        billable: "Billable",
        not_billable: "Not billable",
        billed: "Submitted",
        planned: "Planned",
        finalized: "Finalized",
        entered_in_error: "Not eligible"
      },
      events: {
        mark_as_billed: "Mark as billed",
        mark_as_finalized: "Mark as paid",
        mark_as_not_billable: "Mark as not billable"
      },
      algorithmStatus: "Algorithm Decision",
      algorithmStatuses: {
        fully_reimbursed: {
          title: "Fully reimbursable",
          description: "All items in this charge are reimbursable."
        },
        not_reimbursed_max_year_visits: {
          title: "Not reimbursable - max year visits reached",
          description: "The annual number of visits allowed for this year has been reached. This patient will not be able to receive any more reimbursement until next year."
        },
        not_reimbursed_max_year_units: {
          title: "Not reimbursable - max year units reached",
          description: "The annual maximum number of units allowed for this year has been reached. This patient will not be able to receive any more reimbursement until next year."
        },
        partially_reimbursed_max_visit_units: {
          title: "Partially reimbursable - max visit units reached",
          description: "Maximum reimbursement was made for this charge, but not all units could be reimbursed because there is a cap on the number of units per session. This is common when more than 4 scales are administered in a single measurement."
        },
        partially_reimbursed_max_year_units: {
          title: "Partially reimbursable - max year units reached",
          description: "The patient has hit their annual unit cap for the year, so not all charge items are reimbursable in this measurement. Any future charges in the same year for the same patient will not be reimbursable"
        },
        no_eligible_items: {
          title: "No items currently eligible for reimbursement",
          description: "No items are currently eligible for reimbursement. This may be because the assessment has not been completed, or because the appointment has not happened yet. See the individual charge items for why they are not reimbursable."
        },
        all_items_disqualified: {
          title: "Not reimbursible",
          description: "This measurement did not meet the criteria for reimbursement."
        }
      },
      planned: "Planned",
      plannedDetails: "{units} unit(s) totalling {amount} planned",
      plannedExplanation: "The amount that the algorithm expects to be reimbursed if all participants fill out the assessment and the session meets other criteria for reimbursement (e.g. not canceling the session)",
      billable: "Billable",
      billableDetails: "{units} unit(s) totalling {amount} billable",
      billableExplanation: "The amount based on the list price that is expected to be billed. This is not the same as the amount expected to be paid, which is shown in the expected column.",
      expected: "Expected",
      expectedDetails: "{units} unit(s) totalling {amount} expected",
      expectedExplanation: "The amount our algorithm expects you will receive from the payer if this is submitted. This includes factors such as discounts, intermiitent payment, policy limits etc.",
      billed: "Billed",
      billedDetails: "{units} unit(s) totalling {amount} billed",
      billedExplanation: "The amount based on the list price that was billed. This is not the same as the amount expected to be paid, which is shown in the expected column, or the amount that was paid.",
      received: "Received",
      receivedDetails: "{units} unit(s) totalling {amount} received",
      receivedExplanation: "The amount you were actually paid by the payer for this claim."
    },
    patientConsent: {
      respondedAt: "Date",
      consentType: "Consent Form",
      acceptance: "Accepted?",
      respondent: "Respondent"
    },
    experimentDecision: {
      experimentName: "Experiment",
      experimentVariantName: "Bucket",
      enabled: "Enabled"
    },
    experiment: {
      name: "Name",
      enabled: "Active",
      path: "Path",
      author: "Author",
      variants: "Variants",
      triggerEvents: "Triggering events",
      consent: "Consent Form",
      mode: {
        patient: "Patient only",
        patient_and_guardian: "Patient and guardian",
        patient_and_guardian_if_child: "Patient only if adult; patient and guardian if child",
        guardian: "Guardian only"
      },
      triggerEvent: {
        care_episode_created: {
          title: "Episode of Care Created",
          detail: "triggered whenever a new episode of care is begun"
        },
        user_consent_created: {
          title: "User consents into survey",
          detail: "Users in the target demographic will be asked to consent into the experiment during their next assessment"
        },
        care_episode_condition_created: {
          title: "New Diagnosis",
          detail: "triggered whenever the patient is assigned as having a particular diagnosis"
        }
      }
    },
    blinding: {
      unblinded: "Unblinded",
      export_only: "Available in data export only"
    }
  };
});