define('frontend/pods/components/provider-admin/users/user-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    institute: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        "propertyName": "user.firstName",
        "title": intl.t('model.user.firstName')
      }, {
        "propertyName": "user.lastName",
        "title": intl.t('model.user.lastName'),
        "sortPrecedence": 1,
        "sortDirection": "asc"
      }, {
        "propertyName": "user.email",
        "title": intl.t('model.user.email')
      }, {
        "propertyName": "user.username",
        "title": intl.t('model.user.username')
      }, {
        "title": intl.t('model.provider.organizations'),
        "template": "components/provider-admin/users/user-table/organization-column",
        "sortedBy": "organizations"

      }, {
        "title": intl.t('model.provider.status'),
        "template": "components/provider-admin/users/user-table/disabled-column",
        "sortedBy": "status"
      }, {
        "title": intl.t('model.provider.roles'),
        "template": "components/provider-admin/users/user-table/roles-column",
        "disableSorting": true
      }, {
        "title": intl.t('common.actions'),
        "template": "components/provider-admin/users/user-table/action-buttons"
      }];

      this.set("tableColumns", tableColumns);
      this.set("lastHideDisabled", this.getWithDefault('hideDisabled', 'false'));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      // We need to do a small manual fix to support the switching of disabled users so we can still
      // preserve the ability to set data directly if already loaded. Keep track of the last value and only
      // force a data refresh if it changes.
      var hideDisabled = this.getWithDefault('hideDisabled', 'false');
      if (!this.get("data") || hideDisabled !== this.get("lastHideDisabled")) {
        var data = Ember.Object.create({
          store: this.get("store"),
          modelName: 'provider',
          query: { filter: { hide_disabled: hideDisabled }, include: "stats,user.roles,provider-survey-patient,organizations", sort: "user.lastName" }
        });
        this.set("data", data);
      }

      this.set("lastHideDisabled", hideDisabled);
    }
  });
});