define('frontend/services/scale-templates', ['exports', 'npm:handlebars'], function (exports, _npmHandlebars) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var pageTemplates = [{
    id: 'emptyPage-v1',
    name: 'Page',
    description: 'Contains an empty page with no heading',
    template: '{ "title": "{{title}}", "properties": {}, "questions": [] }',
    parameters: {
      title: {
        name: "Page Title",
        description: "Page title, used when in bulk edit mode",
        type: "text",
        required: true
      }
    },
    summaryComponent: 'scale-creation/page-summary'
  }, {
    id: 'pageWithHeader-v1',
    name: 'Page with header',
    description: 'Page with persisted header, similiar to the PHQ-9.',
    template: '{ "properties" : { "header": {{{translatedText header}}}, "footer": {{{translatedText footer}}} }, "title": "{{title}}" }',
    parameters: {
      title: {
        name: "Page Title",
        description: "Page title, used when in bulk edit mode",
        type: "text",
        required: true
      },
      header: {
        name: "Page Heading",
        description: "Page heading, placed persistently at the top of the page",
        type: "translatedText"
      }
    },
    summaryComponent: 'scale-creation/page-summary'
  }];

  var surveyTemplates = [{
    id: 'emptySurvey-v1',
    name: 'Survey',
    description: 'Contains an empty survey',
    template: '{ "content": { "pages": [] } }',
    parameters: {}
  }];

  var questionTemplates = [{
    id: 'radioQuestion-v1',
    name: 'Radio Question',
    description: 'A radio button with multiple choices',
    template: '{\n            "type": "RADIO",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "required": {{required}},\n              "orientation": "VERT",\n              "disabled": false\n            },\n            "options": [\n              {{#each options}}\n                {\n                  "id": {{id}},\n                  "value": "{{value}}",\n                  "normalized_score": {{{withNull normalized_score}}},\n                  "item_coding": {{{withNull item_coding}}},\n                  "report_text": {{{withNull report_text}}},\n                  "title": {{{translatedText this}}}\n                }\n                {{#unless @last}},{{/unless}}\n              {{/each}}\n            ]\n          }',
    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      options: {
        name: "Answers",
        description: "The answers for the question. One may be selected",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }, {
    id: 'radioQuestionWithOther-v1',
    name: 'Radio Question With Other',
    description: 'A radio button with multiple choices and an other option',
    template: '{\n            "type": "RADIO",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "required": {{required}},\n              "orientation": "VERT",\n              "disabled": false\n            },\n            "options": [\n              {{#each options}}\n                {\n                  "id": {{id}},\n                  "value": "{{value}}",\n                  "normalized_score": {{{withNull normalized_score}}},\n                  "item_coding": {{{withNull item_coding}}},\n                  "report_text": {{{withNull report_text}}},\n                  "title": {{{translatedText this}}}\n                },\n              {{/each}}\n              {\n                "value": "other",\n                "properties": {\n                  "other": true\n                },\n                "title": {{{translatedText otherText}}}\n              }\n            ]\n          }',
    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      options: {
        name: "Answers",
        description: "The answers for the question. One may be selected",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      otherText: {
        name: "Other Text",
        description: "The text for the 'other' label",
        type: "translatedText"
      }
    }
  }, {
    id: 'gridRadioQuestion-v1',
    name: 'Grid Radio Question',
    description: 'A radio button with multiple choices',
    template: '{\n            "type": "RADIO",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "required": {{required}},\n              "type": "grid",\n              "disabled": false,\n              "showValue": {{showValue}},\n              "textSize": {{{json textSize}}}\n            },\n            "options": [\n              {{#each options}}\n                {\n                  "id": {{id}},\n                  "value": "{{value}}",\n                  "normalized_score": {{{withNull normalized_score}}},\n                  "item_coding": {{{withNull item_coding}}},\n                  "report_text": {{{withNull report_text}}},\n                  "title": {{{translatedText this}}}\n                }\n                {{#unless @last}},{{/unless}}\n              {{/each}}\n            ]\n          }',
    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      options: {
        name: "Answers",
        description: "The answers for the question. One may be selected",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      showValue: {
        name: "Show Value",
        description: "Show the value for each question in the button",
        type: 'checkbox',
        defaultValue: false
      },
      textSize: {
        name: "Text Size",
        description: "How big the text above the labels should be",
        advanced: true,
        type: 'select',
        options: [{ id: 'regular', name: 'Regular' }, { id: 'small', name: 'Small' }, { id: 'xs', name: 'XS' }],
        defaultValue: 'regular'
      }
    }
  }, {
    id: 'norseLikertQuestion-v1',
    name: 'NORSE Likert Question',
    description: 'A likert for a norse question',
    template: '{\n            "type": "RADIO",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "required": {{required}},\n              "map_key": "radio-likert",\n              "hidden": {{hidden}},\n              "likertHeaders": {\n                "left" : {{{translatedText leftText}}},\n                "right" : {{{translatedText rightText}}}\n              }\n            },\n            "options": [\n              {{#each options}}\n                {\n                  "id": {{id}},\n                  "value": "{{value}}",\n                  "normalized_score": {{{withNull normalized_score}}},\n                  "item_coding": {{{withNull item_coding}}},\n                  "report_text": {{{withNull report_text}}},\n                  "title": {{{translatedText this}}}\n                }\n                {{#unless @last}},{{/unless}}\n              {{/each}}\n            ]\n          }',
    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      hidden: {
        name: "Starts Hidden",
        description: "The question starts hidden",
        type: "checkbox",
        defaultValue: true
      },
      leftText: {
        name: "Left Text",
        description: "The text of the left hand side value",
        type: "translatedText",
        defaultValue: function defaultValue() {
          return { en: "Not at all", es: "Absolutamente falso" };
        }
      },
      rightText: {
        name: "Right Text",
        description: "The text of the left hand side value",
        type: "translatedText",
        defaultValue: function defaultValue() {
          return { en: "True for me", es: "Cierto para mi" };
        }
      },
      options: {
        name: "Answers",
        description: "The answers for the question. One may be selected",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }, {
    id: 'norseLikertQuestionWithNa-v1',
    name: 'NORSE Likert Question With N/A',
    description: 'A likert for a norse question but includes a secondary na option',
    template: '{\n            "type": "RADIO",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "required": {{required}},\n              "map_key": "radio-likert",\n              "hidden": {{hidden}},\n              "likertHeaders": {\n                "left" : {{{translatedText leftText}}},\n                "right" : {{{translatedText rightText}}}\n              }\n            },\n            "options": [\n              {{#each options}}\n                {\n                  "id": {{id}},\n                  "value": "{{value}}",\n                  "normalized_score": {{{withNull normalized_score}}},\n                  "item_coding": {{{withNull item_coding}}},\n                  "report_text": {{{withNull report_text}}},\n                  "title": {{{translatedText this}}}\n                }\n                ,\n              {{/each}}\n                {\n                  "id": 12715,\n                  "title": {\n                    "en": "N/A"\n                  },\n                  "value": "na",\n                  "properties": {\n                    "secondary": true,\n                    "disabled": false\n                  }\n                }\n            ]\n          }',
    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      hidden: {
        name: "Starts Hidden",
        description: "The question starts hidden",
        type: "checkbox",
        defaultValue: true
      },
      leftText: {
        name: "Left Text",
        description: "The text of the left hand side value",
        type: "translatedText",
        defaultValue: function defaultValue() {
          return { en: "Not at all", es: "Absolutamente falso" };
        }
      },
      rightText: {
        name: "Right Text",
        description: "The text of the left hand side value",
        type: "translatedText",
        defaultValue: function defaultValue() {
          return { en: "True for me", es: "Cierto para mi" };
        }
      },
      options: {
        name: "Answers",
        description: "The answers for the question. One may be selected",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }, {
    id: 'yesNoQuestion-v1',
    name: 'Yes/No Question',
    description: 'A radio button with yes and no',
    template: '{\n            "type": "RADIO",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "required": {{required}},\n              "orientation": "VERT",\n              "disabled": false\n            },\n            "options": [\n              {{#each options}}\n                {\n                  "id": {{id}},\n                  "value": "{{value}}",\n                  "normalized_score": {{{withNull normalized_score}}},\n                  "item_coding": {{{withNull item_coding}}},\n                  "title": {{{translatedText this}}},\n                  "report_text": {{{withNull report_text}}}\n                }\n                {{#unless @last}},{{/unless}}\n              {{/each}}\n            ]\n          }',
    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      options: {
        name: "Answers",
        description: "The answers for the question. One may be selected",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [{
            id: 1,
            normalized_score: 1,
            en: "Yes",
            es: "Si",
            value: "yes"
          }, {
            id: 2,
            normalized_score: 7,
            en: "No",
            es: "No",
            value: "no"
          }];
        }
      }
    }
  }, {
    id: 'yesNoQuestionNumeric-v1',
    name: 'Yes/No Question',
    description: 'A radio button with yes and no where yes = 1 and no = 0',
    template: '{\n            "type": "RADIO",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "required": {{required}},\n              "orientation": "VERT",\n              "disabled": false\n            },\n            "options": [\n              {{#each options}}\n                {\n                  "id": {{id}},\n                  "value": "{{value}}",\n                  "normalized_score": {{{withNull normalized_score}}},\n                  "item_coding": {{{withNull item_coding}}},\n                  "title": {{{translatedText this}}},\n                  "report_text": {{{withNull report_text}}}\n                }\n                {{#unless @last}},{{/unless}}\n              {{/each}}\n            ]\n          }',
    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      options: {
        name: "Answers",
        description: "The answers for the question. One may be selected",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [{
            id: 1,
            en: "Yes",
            value: 1,
            report_text: "Yes"
          }, {
            id: 2,
            en: "No",
            value: 0,
            report_text: "No"
          }];
        }
      }
    }
  }, {
    id: 'selectQuestion-v1',
    name: 'Select Question',
    description: 'A select component with multiple options',
    template: '{\n            "type": "SELECT",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "required": {{required}},\n              "disabled": false,\n              "blankText": {{{translatedText blankText}}}\n            },\n            "options": [\n              {{#each options}}\n                {\n                  "id": {{id}},\n                  "value": "{{value}}",\n                  "normalized_score": {{{withNull normalized_score}}},\n                  "item_coding": {{{withNull item_coding}}},\n                  "title": {{{translatedText this}}},\n                  "report_text": {{{withNull report_text}}}\n                }\n                {{#unless @last}},{{/unless}}\n              {{/each}}\n            ]\n          }',
    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      blankText: {
        name: "Blank Text",
        description: "The text when nothing is selected.",
        type: "translatedText"
      },
      options: {
        name: "Answers",
        description: "The answers for the question. One may be selected",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [{
            id: 1,
            normalized_score: 1,
            en: "Yes",
            es: "Si",
            value: "yes"
          }, {
            id: 2,
            normalized_score: 7,
            en: "No",
            es: "No",
            value: "no"
          }];
        }
      }
    }
  }, {
    id: 'likert-v1',
    name: 'Likert Question',
    description: 'A likert scale',
    writeContent: function writeContent(parameters) {
      var normalizedScoreMap = {
        2: [1, 7],
        3: [1, 4, 7],
        4: [1, 2, 6, 7],
        5: [1, 2, 4, 6, 7],
        6: [1, 2, 4, 4, 6, 7],
        7: [1, 2, 4, 4, 4, 6, 7],
        10: [1, 1, 2, 2, 4, 4, 6, 6, 7, 7],
        11: [1, 1, 2, 2, 4, 4, 4, 6, 6, 7, 7]
      };

      var data = {
        type: 'RADIO',
        title: parameters.title,
        properties: {
          required: parameters.required,
          disabled: false,
          orientation: "STACKED",
          labels_right: true,
          map_key: 'radio-likert',
          likertHeaders: {
            left: parameters.leftText,
            right: parameters.rightText
          }
        },
        options: []
      };

      var numberOfOptions = parameters.maxValue - parameters.minValue + 1;

      for (var index = 0, ii = parameters.minValue; ii <= parameters.maxValue; index++, ii++) {
        data.options.push({
          value: ii.toString(),
          normalized_score: parameters.reversed ? normalizedScoreMap[numberOfOptions][numberOfOptions - index - 1] : normalizedScoreMap[numberOfOptions][index],
          title: {
            en: ii.toString()
          }
        });
      }

      return data;
    },

    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      reversed: {
        name: "Reversed",
        description: "Whether this likert weight should be reversed",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      leftText: {
        name: "Left Text",
        description: "The text of the left hand side value",
        type: "translatedText",
        defaultValue: function defaultValue() {
          return { en: "Not at all" };
        }
      },
      rightText: {
        name: "Right Text",
        description: "The text of the left hand side value",
        type: "translatedText",
        defaultValue: function defaultValue() {
          return { en: "True for me" };
        }
      },
      minValue: {
        name: "Min Value",
        description: "The minimum value",
        type: "number",
        defaultValue: 1
      },
      maxValue: {
        name: "Max Value",
        description: "The maximum value",
        type: "number",
        defaultValue: 7
      }
    }
  }, {
    id: 'likertWithOptions-v1',
    name: 'Likert With Options',
    description: 'A likert question but you specify the individual options',
    template: '{\n            "type": "RADIO",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "required": {{required}},\n              "orientation": "VERT",\n              "disabled": false,\n              "map_key": "radio-likert",\n              "likertHeaders": {\n                "left": {{{json leftText}}},\n                "right": {{{json rightText}}}\n              }\n            },\n            "options": [\n              {{#each options}}\n                {\n                  "id": {{id}},\n                  "value": "{{value}}",\n                  "normalized_score": {{{withNull normalized_score}}},\n                  "item_coding": {{{withNull item_coding}}},\n                  "report_text": {{{withNull report_text}}},\n                  "title": {{{translatedText this}}}\n                }\n                {{#unless @last}},{{/unless}}\n              {{/each}}\n            ]\n          }',
    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      options: {
        name: "Answers",
        description: "The answers for the question. One may be selected",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      leftText: {
        name: "Left Text",
        description: "The text of the left hand side value",
        type: "translatedText",
        defaultValue: function defaultValue() {
          return { en: "Not at all" };
        }
      },
      rightText: {
        name: "Right Text",
        description: "The text of the left hand side value",
        type: "translatedText",
        defaultValue: function defaultValue() {
          return { en: "True for me" };
        }
      }
    }
  }, {
    id: 'advancedLikert-v1',
    name: 'Advanced Likert Question',
    description: 'A likert scale where you can customize the exact text above',
    writeContent: function writeContent(parameters) {
      var headers = parameters.headers.map(function (header) {
        return {
          position: header.position,
          text: {
            en: header.title
          },
          alignment: header.alignment
        };
      });
      var data = {
        type: 'RADIO',
        title: parameters.title,
        properties: {
          required: parameters.required,
          disabled: false,
          orientation: "STACKED",
          labels_right: true,
          map_key: 'radio-likert',
          advancedLikert: headers
        }
      };

      if (parameters.options) {
        data.options = parameters.options.map(function (option) {
          return {
            title: {
              en: option.en,
              es: option.es
            },
            normalized_score: option.normalized_score,
            value: option.value,
            report_text: option.report_text
          };
        });
      }
      return data;
    },

    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      headers: {
        name: "Headers",
        description: "All the likert header data",
        type: "subList",
        columns: [{
          property: "title",
          title: "Title",
          type: 'text',
          size: 15
        }, {
          property: "position",
          title: "Position",
          type: 'number',
          size: 5
        }, {
          property: "alignment",
          title: "Alignment",
          type: "select",
          options: ['left', 'center', 'right'],
          size: 5,
          defaultValue: 'center'
        }],
        defaultValue: function defaultValue() {
          return [];
        }
      },
      options: {
        name: "Answers",
        description: "The answers for the question. One may be selected",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [{
            normalized_score: 1,
            en: "1",
            es: null,
            value: "1",
            report_text: "Not at all"
          }, {
            normalized_score: 2,
            en: "2",
            es: null,
            value: "2",
            report_text: "A little"
          }, {
            normalized_score: 3,
            en: "3",
            es: null,
            value: "3",
            report_text: "More than a little"
          }, {
            normalized_score: 4,
            en: "4",
            es: null,
            value: "4",
            report_text: "Somewhat"
          }, {
            normalized_score: 5,
            en: "5",
            es: null,
            value: "5",
            report_text: "Quite a lot"
          }, {
            normalized_score: 6,
            en: "6",
            es: null,
            value: "6",
            report_text: "A lot"
          }, {
            normalized_score: 7,
            en: "7",
            es: null,
            value: "7",
            report_text: "Always"
          }];
        }
      }

    }
  }, {
    id: 'textInput-v1',
    name: 'Text Input',
    description: 'A single input text box',
    template: '{\n            "type": "TEXTBOX",\n            "title": {{{translatedText title}}},\n            "properties": {}\n          }',
    parameters: {
      title: {
        name: "Question Text",
        description: "Full text of the question",
        type: "translatedText",
        required: true
      }
    }
  }, {
    id: 'numberInput-v1',
    name: 'Number Input',
    description: 'A single input box that will only take a number',
    template: '{\n            "type": "TEXTBOX",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "type": "number",\n              "units": {{{translatedText units}}},\n              "min": {{withNull min}},\n              "max": {{withNull max}}\n            }\n          }',
    parameters: {
      title: {
        name: "Question Text",
        description: "Full text of the question",
        type: "translatedText",
        required: true
      },
      min: {
        name: "Min",
        description: "The minimum allowed number. Leave blank for none.",
        type: "number"
      },
      max: {
        name: "Max",
        description: "The maximum allowed number. Leave blank for none.",
        type: "number"
      },
      units: {
        name: "Units",
        description: "Units for the number",
        type: "translatedText"
      }
    }
  }, {
    id: 'dateInput-v1',
    name: 'Date Input',
    description: 'A single input box that will only take a date',
    template: '{\n            "type": "TEXTBOX",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "type": "date"\n            }\n          }',
    parameters: {
      title: {
        name: "Question Text",
        description: "Full text of the question",
        type: "translatedText",
        required: true
      }
    }
  }, {
    id: 'essayInput-v1',
    name: 'Essay Input',
    description: 'A large text box for larger amounts of text',
    template: '{\n            "type": "ESSAY",\n            "title": {{{translatedText title}}},\n            "properties": {}\n          }',
    parameters: {
      title: {
        name: "Question Text",
        description: "Full text of the question",
        type: "translatedText",
        required: true
      }
    }
  }, {
    id: 'optionalComment-v1',
    name: 'Additional Comment Input',
    description: 'A large text box for larger amounts of text. Answering is optional so missing answers will not be flagged on report',
    template: '{\n            "type": "ESSAY",\n            "title": {{{translatedText title}}},\n            "properties": {},\n            "display_data": {\n              "optional": true\n            }\n          }',
    parameters: {
      title: {
        name: "Question Text",
        description: "Full text of the question",
        type: "translatedText",
        required: false
      }
    }
  }, {
    id: 'checkboxQuestion-v1',
    name: 'Checkbox Question',
    description: 'A multiple answer checkbox question',
    template: '{\n            "type": "CHECKBOX",\n            "title": {{{translatedText title}}},\n            "properties": {\n            },\n            "options": [\n              {{#each options}}\n                {\n                  "id": {{id}},\n                  "value": "{{value}}",\n                  "normalized_score": {{{withNull normalized_score}}},\n                  "item_coding": {{{withNull item_coding}}},\n                  "report_text": {{{withNull report_text}}},\n                  "title": {{{translatedText this}}}\n                }\n                {{#unless @last}},{{/unless}}\n              {{/each}}\n            ]\n          }',
    parameters: {
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      options: {
        name: "Answers",
        description: "The answers for the question. Multiple may be selected",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }, {
    id: 'checkboxQuestionWithOther-v1',
    name: 'Checkbox With Other Question',
    description: 'A multiple answer checkbox question with a section for filling in "other" text',
    template: '{\n            "type": "CHECKBOX",\n            "title": {{{translatedText title}}},\n            "properties": {\n            },\n            "options": [\n              {{#each options}}\n                {\n                  "id": {{id}},\n                  "value": "{{value}}",\n                  "normalized_score": {{{withNull normalized_score}}},\n                  "item_coding": {{{withNull item_coding}}},\n                  "report_text": {{{withNull report_text}}},\n                  "title": {{{translatedText this}}}\n                },\n              {{/each}}\n              {\n                "value": "other",\n                "properties": {\n                  "other": true\n                },\n                "title": {{{translatedText otherText}}}\n              }\n            ]\n          }',
    parameters: {
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      options: {
        name: "Answers",
        description: "The answers for the question. Does not include the other box, which will be last",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      otherText: {
        name: "Other Text",
        description: "The text for the 'other' label",
        type: "translatedText"
      }
    }
  }, {
    id: 'dynamicVariableCheckboxQuestion-v1',
    name: 'Dynamic Variable Checkbox Question',
    description: 'A multiple answer checkbox question that assigns each selected answer into a separate variable for use in more complicated branching logic',
    template: '{\n            "type": "DYNAMICVARIABLECHECKBOX",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "limit": {{withNull limit}},\n              "compact": {{compact}},\n              "showRank": {{showRank}}\n            },\n            "options": [\n              {{#each options}}\n                {\n                  "id": {{id}},\n                  "value": "{{value}}",\n                  "normalized_score": {{{withNull normalized_score}}},\n                  "item_coding": {{{withNull item_coding}}},\n                  "report_text": {{{withNull report_text}}},\n                  "title": {{{translatedText this}}},\n                  "properties": {\n                    "other": {{{withNull other}}}\n                  }\n                }\n                {{#unless @last}},{{/unless}}\n              {{/each}}\n            ]\n          }',
    writeContent: function writeContent(parameters, properties) {
      // Generate identical subquestions.
      var handlebarsTemplate = _npmHandlebars.default.compile(this.template);
      var contentJson = JSON.parse(handlebarsTemplate(parameters));

      var subquestions = [];

      var reportTextBase = properties.get("report_text") || contentJson.title.en;

      var limit = parameters.limit || contentJson.options.length;

      for (var ii = 1; ii <= limit; ii++) {
        subquestions.push({
          title: contentJson.title,
          type: 'HIDDEN',
          shortname: '' + properties.get('shortname') + ii,
          options: contentJson.options,
          display_data: {
            report_text: reportTextBase + ' Answer ' + ii
          }
        });
      }

      contentJson.sub_questions = subquestions;

      return contentJson;
    },

    parameters: {
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      options: {
        name: "Answers",
        description: "The answers for the question. Multiple may be selected",
        type: 'questionOptions',
        allowWriteIn: true,
        defaultValue: function defaultValue() {
          return [];
        }
      },
      limit: {
        name: "Limit",
        description: "How many answers can be selected",
        type: 'number',
        defaultValue: 5
      },
      showRank: {
        name: "Show Rank?",
        description: "When answers are selected, show which order they are in",
        type: 'checkbox',
        defaultValue: false
      },
      compact: {
        name: "Compact mode?",
        description: "Use all available space by splitting answers into columns",
        type: 'checkbox',
        defaultValue: false
      }
    }
  }, {
    id: 'slider-v1',
    name: 'Slider Question',
    description: 'A slider with min and max values',
    writeContent: function writeContent(parameters) {
      var headers = parameters.headers.map(function (header) {
        return {
          position: header.position,
          text: {
            en: header.title
          },
          alignment: header.alignment
        };
      });
      var data = {
        type: 'FULLSLIDER',
        title: parameters.title,
        properties: {
          disabled: false,
          orientation: "STACKED",
          labels_right: true,
          map_key: 'radio-likert',
          advancedLikert: headers,
          max_value: parameters.maxValue,
          min_value: parameters.minValue,
          default_value: parameters.defaultValue,
          step: parameters.step
        }
      };

      if (parameters.options) {
        data.options = parameters.options.map(function (option) {
          return {
            title: {
              en: option.en,
              es: option.es
            },
            normalized_score: option.normalized_score,
            value: option.value,
            report_text: option.report_text
          };
        });
      }
      return data;
    },


    parameters: {
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      headers: {
        name: "Headers",
        description: "All the likert header data",
        type: "subList",
        columns: [{
          property: "title",
          title: "Title",
          type: 'text',
          size: 15
        }, {
          property: "position",
          title: "Position",
          type: 'number',
          size: 5
        }, {
          property: "alignment",
          title: "Alignment",
          type: "select",
          options: ['left', 'center', 'right'],
          size: 5,
          defaultValue: 'center'
        }],
        defaultValue: function defaultValue() {
          return [];
        }
      },
      minValue: {
        name: "Min Value",
        description: "The minimum value",
        type: "number",
        defaultValue: 0
      },
      maxValue: {
        name: "Max Value",
        description: "The maximum value",
        type: "number",
        defaultValue: 100
      },
      defaultValue: {
        name: "Default Value",
        description: "The starting value",
        type: "number",
        defaultValue: 50
      },
      step: {
        name: "Step",
        description: "The step between values",
        type: "number",
        defaultValue: 5
      }
    }
  }, {
    id: 'norseSlider-v1',
    name: 'NORSE Slider',
    description: 'Slider question for NORSE',
    template: '{\n            "type": "SLIDER",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "messages": {\n                "center_label": {\n                  "en": "same",\n                  "es": "igual"\n                },\n                "right_label": {{{translatedText rightLabel}}},\n                "left_label": {{{translatedText leftLabel}}},\n                "na_text": {\n                  "en": "Not Applicable",\n                  "es": "No Aplica"\n                }\n              }\n            },\n            "options": [\n            {{#each options}}\n              {\n                "id": {{id}},\n                "value": "{{value}}",\n                "normalized_score": {{{withNull normalized_score}}},\n                "item_coding": {{{withNull item_coding}}},\n                "report_text": {{{withNull report_text}}},\n                "title": {{{translatedText this}}}\n              }\n              {{#unless @last}},{{/unless}}\n            {{/each}}\n            ]\n          }',
    parameters: {
      title: {
        name: "Title",
        description: "Title",
        type: "translatedText"
      },
      leftLabel: {
        name: "Left Label",
        description: "The text of the left label",
        type: "translatedText"
      },
      rightLabel: {
        name: "Right Label",
        description: "The text of the right label",
        type: "translatedText"
      },
      options: {
        name: "Options",
        description: "The answers for the question. These are not displayed directly",
        type: 'questionOptions',
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }, {
    id: 'instructions-v1',
    name: 'Instructions',
    description: 'Text for instructions',
    template: '{\n            "type": "INSTRUCTIONS",\n            "title": {{{translatedText title}}},\n            "properties": {}\n          }',
    parameters: {
      title: {
        name: "Text",
        description: "Instruction text",
        type: "translatedText"
      }
    }
  }, {
    id: 'monthYearQuestion-v1',
    name: 'Month Year Question',
    description: 'A component which allows you to select a month and year in the past',
    template: '{\n            "type": "MONTHYEAR",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "required": {{required}},\n              "disabled": false,\n              "yearLookback": {{yearLookback}},\n              "showNa": {{showNa}},\n              "naText": {{{translatedText naText}}}\n            }\n          }',
    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      yearLookback: {
        name: "Year lookback",
        description: "How many years to look back in the dropdown",
        type: "number",
        defaultValue: 30
      },
      showNa: {
        name: "Show N/A",
        description: "Whether to show an N/A checkbox",
        type: "checkbox",
        defaultValue: false
      },
      naText: {
        name: "N/A Text",
        description: "The next of the N/A option",
        type: "translatedText"
      }
    }
  }, {
    id: 'yearRange-v1',
    name: 'Year Range Question',
    description: 'A component which allows you to select a date range',
    template: '{\n            "type": "YEARRANGE",\n            "title": {{{translatedText title}}},\n            "properties": {\n              "required": {{required}},\n              "disabled": false,\n              "yearLookback": {{yearLookback}},\n              "showNa": {{showNa}},\n              "naText": {{{translatedText naText}}}\n            }\n          }',
    parameters: {
      required: {
        name: "Required?",
        description: "Whether this question must be answered",
        type: "checkbox",
        defaultValue: false
      },
      title: {
        name: "Question Text",
        description: "The text of the question",
        type: "translatedText"
      },
      yearLookback: {
        name: "Year lookback",
        description: "How many years to look back in the dropdown",
        type: "number",
        defaultValue: 30
      },
      showNa: {
        name: "Show N/A",
        description: "Whether to show an N/A checkbox",
        type: "checkbox",
        defaultValue: false
      },
      naText: {
        name: "N/A Text",
        description: "The next of the N/A option",
        type: "translatedText"
      }
    }
  }];

  var scorerTemplates = [{
    id: 'simplesum-v1',
    name: 'Simple Sum (Integer)',
    description: 'Add together all values as integers of all non-instruction questions. Not valid if any questions missed',
    template: '{\n      "score_class_name": "Scoring::IntegerSumScorer"\n    }'
  }, {
    id: 'multipliedsum-v1',
    name: 'Multiplied Sum (Integer)',
    description: 'Add together all values of all non-instruction questions and multiply by the given factor. Not valid if any questions missed',
    template: '{\n      "score_class_name": "Scoring::IntegerSumMultipleScorer",\n      "params": {\n        "multiple": {{multiple}}\n      }\n    }',
    parameters: {
      multiple: {
        name: "Multiple",
        description: "The integer to multiply the sum by",
        type: 'integer',
        defaultValue: 2,
        required: true
      }
    }
  }, {
    id: 'subsetsum-v1',
    name: 'Subset Sum (Integer)',
    description: 'Add together all the specified questions. Not valid if any questions missed',
    template: '{\n      "score_class_name": "Scoring::IntegerSubsetSumScorer",\n      "params": {\n        "scored_questions": {{{json scoredQuestions}}}\n      }\n    }',
    parameters: {
      scoredQuestions: {
        name: "Scored questions",
        description: "The mnemonics of the questions that should be scored",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }, {
    id: 'decimalsum-v1',
    name: 'Decimal Sum',
    description: 'Add together all values of all non-instruction questions as fractions/decimals. Not valid if any questions missed',
    template: '{\n      "score_class_name": "Scoring::DecimalSumScorer"\n    }'
  }, {
    id: 'maxsetsumscorer-v1',
    name: 'Sum of Max of Sets',
    description: 'Take the maximum value of each set of questions and add the whole result together. Leave sets blank to not use them',
    writeContent: function writeContent(parameters) {
      // Just change all empty arrays to nils to serialize.
      var params = {};

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(parameters)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref = _step.value;

          var _ref2 = _slicedToArray(_ref, 2);

          var key = _ref2[0];
          var value = _ref2[1];

          if (value && value.length > 0) {
            params[key] = value;
          } else {
            params[key] = null;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return {
        score_class_name: "Scoring::IntegerMaxSetSumScorer",
        params: params
      };
    },

    parameters: {
      set1: {
        name: "Set 1",
        description: "The mnemonics of the questions that should be scored in set 1",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set2: {
        name: "Set 2",
        description: "The mnemonics of the questions that should be scored in set 2",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set3: {
        name: "Set 3",
        description: "The mnemonics of the questions that should be scored in set 3",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set4: {
        name: "Set 4",
        description: "The mnemonics of the questions that should be scored in set 4",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set5: {
        name: "Set 5",
        description: "The mnemonics of the questions that should be scored in set 5",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set6: {
        name: "Set 6",
        description: "The mnemonics of the questions that should be scored in set 6",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set7: {
        name: "Set 7",
        description: "The mnemonics of the questions that should be scored in set 7",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set8: {
        name: "Set 8",
        description: "The mnemonics of the questions that should be scored in set 8",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set9: {
        name: "Set 9",
        description: "The mnemonics of the questions that should be scored in set 9",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set10: {
        name: "Set 10",
        description: "The mnemonics of the questions that should be scored in set 10",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set11: {
        name: "Set 11",
        description: "The mnemonics of the questions that should be scored in set 11",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set12: {
        name: "Set 12",
        description: "The mnemonics of the questions that should be scored in set 12",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set13: {
        name: "Set 13",
        description: "The mnemonics of the questions that should be scored in set 13",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set14: {
        name: "Set 14",
        description: "The mnemonics of the questions that should be scored in set 14",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set15: {
        name: "Set 15",
        description: "The mnemonics of the questions that should be scored in set 15",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set16: {
        name: "Set 16",
        description: "The mnemonics of the questions that should be scored in set 16",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set17: {
        name: "Set 17",
        description: "The mnemonics of the questions that should be scored in set 17",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set18: {
        name: "Set 18",
        description: "The mnemonics of the questions that should be scored in set 18",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set19: {
        name: "Set 19",
        description: "The mnemonics of the questions that should be scored in set 19",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set20: {
        name: "Set 20",
        description: "The mnemonics of the questions that should be scored in set 20",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set21: {
        name: "Set 21",
        description: "The mnemonics of the questions that should be scored in set 21",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set22: {
        name: "Set 22",
        description: "The mnemonics of the questions that should be scored in set 22",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set23: {
        name: "Set 23",
        description: "The mnemonics of the questions that should be scored in set 23",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set24: {
        name: "Set 24",
        description: "The mnemonics of the questions that should be scored in set 24",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set25: {
        name: "Set 25",
        description: "The mnemonics of the questions that should be scored in set 25",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set26: {
        name: "Set 26",
        description: "The mnemonics of the questions that should be scored in set 26",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      },
      set27: {
        name: "Set 27",
        description: "The mnemonics of the questions that should be scored in set 27",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return null;
        }
      }
    }
  }, {
    id: 'simplemean-v1',
    name: 'Simple Mean',
    description: 'Compute the mean of all questions answered. Not valid if any questions missed',
    template: '{\n      "score_class_name": "Scoring::MeanScorer"\n    }'
  }, {
    id: 'subsetmean-v1',
    name: 'Subset Mean',
    description: 'Mean of the given subset of questions. Not valid if any questions missed',
    template: '{\n      "score_class_name": "Scoring::MeanScorer",\n      "params": {\n        "scored_questions": {{{json scoredQuestions}}}\n      }\n    }',
    parameters: {
      scoredQuestions: {
        name: "Scored questions",
        description: "The mnemonics of the questions that should be scored",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }, {
    id: 'meanWithMissing-v1',
    name: 'Mean With Missing',
    description: 'Mean of the specified questions. You may miss up to a certain number of questions and they will be scored at the given score.',
    template: '{\n      "score_class_name": "Scoring::MeanWithMissingScorer",\n      "params": {\n        "missing_allowed": {{missingAllowed}}\n      }\n    }',
    parameters: {
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 1;
        }
      }
    }
  }, {
    id: 'subsetMeanWithMissing-v1',
    name: 'Subset Mean With Missing',
    description: 'Take the mean of the given subset of questions. You may miss up to a certain number of questions',
    template: '{\n      "score_class_name": "Scoring::SubsetMeanWithMissingScorer",\n      "params": {\n        "scored_questions": {{{json scoredQuestions}}},\n        "missing_allowed": {{missingAllowed}}\n      }\n    }',
    parameters: {
      scoredQuestions: {
        name: "Scored questions",
        description: "The mnemonics of the questions that should be scored",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 1;
        }
      }
    }
  }, {
    id: 'sumIfAdministered-v1',
    name: 'Sum If Administered',
    description: 'Add together all the specified questions. Score 0 and be valid if no questions ever answered',
    template: '{\n      "score_class_name": "Scoring::SumIfAdministeredScorer",\n      "params": {\n        "scored_questions": {{{json scoredQuestions}}}\n      }\n    }',
    parameters: {
      scoredQuestions: {
        name: "Scored questions",
        description: "The mnemonics of the questions that should be scored",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }, {
    id: 'triggeredSubsetMeanWithMissing-v1',
    name: 'Triggered Subset Mean With Missing',
    description: 'Use on trigger question to decide if the result should be calculated at all. Then take the mean of the given subset of questions. You may miss up to a certain number of questions',
    template: '{\n      "score_class_name": "Scoring::TriggeredMeanWithMissingScorer",\n      "params": {\n        "trigger_question": {{{json triggerQuestion}}},\n        "trigger_value": {{triggerValue}},\n        "scored_questions": {{{json scoredQuestions}}},\n        "missing_allowed": {{missingAllowed}}\n      }\n    }',
    parameters: {
      scoredQuestions: {
        name: "Scored questions",
        description: "The mnemonics of the questions that should be scored",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 1;
        }
      },
      triggerQuestion: {
        name: "Trigger question",
        description: "Which question to use as a trigger",
        type: 'text',
        required: true
      },
      triggerValue: {
        name: "Trigger value",
        description: "The minimum value at which the scale will be administered",
        type: 'number',
        required: true,
        defaultValue: function defaultValue() {
          return 1;
        }
      }
    }
  }, {
    id: 'meanWithReversed-v1',
    name: 'Mean With Reversed Items',
    description: 'Mean of the specified questions, but some of the items are reversed. You may miss up to a certain number of questions.',
    template: '{\n      "score_class_name": "Scoring::MeanWithReversedScorer",\n      "params": {\n        "normal_questions": {{{json normalQuestions}}},\n        "reversed_questions": {{{json reversedQuestions}}},\n        "reverse_pivot": {{reversePivot}},\n        "missing_allowed": {{missingAllowed}}\n      }\n    }',
    parameters: {
      normalQuestions: {
        name: "Normally-scored questions",
        description: "The mnemonics of the questions that should be scored normally",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      reversedQuestions: {
        name: "Reverse-scored questions",
        description: "The mnemonics of the questions that should have their scores inverted",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      reversePivot: {
        name: "Reverse pivot",
        description: "The number from which to subtract the reverse scored questions. E.g. if you have a 1-5 scoring rubric, 6 will translate that into a 5-1 score",
        type: 'number',
        defaultValue: function defaultValue() {
          return 5;
        }
      },
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 1;
        }
      }
    }
  }, {
    id: 'normalizedUpscaleScorer-v1',
    name: 'Normalized Upscale Scorer With Reversed',
    description: 'Mean of the specified questions, but some of the items are reversed. You may miss up to a certain number of questions.',
    template: '{\n      "score_class_name": "Scoring::UpscaleNormalizedScorer",\n      "params": {\n        "normal_questions": {{{json normalQuestions}}},\n        "reversed_questions": {{{json reversedQuestions}}},\n        "reverse_pivot": {{reversePivot}},\n        "normalized_min": {{normalizedMin}},\n        "normalized_max": {{normalizedMax}},\n        "raw_min": {{rawMin}},\n        "raw_max": {{rawMax}},\n        "missing_allowed": {{missingAllowed}}\n      }\n    }',
    parameters: {
      normalQuestions: {
        name: "Normally-scored questions",
        description: "The mnemonics of the questions that should be scored normally",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      reversedQuestions: {
        name: "Reverse-scored questions",
        description: "The mnemonics of the questions that should have their scores inverted",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      reversePivot: {
        name: "Reverse pivot",
        description: "The number from which to subtract the reverse scored questions. E.g. if you have a 1-5 scoring rubric, 6 will translate that into a 5-1 score",
        type: 'number',
        defaultValue: function defaultValue() {
          return 5;
        }
      },
      normalizedMin: {
        name: "Normalized Min",
        description: "The lowest score on the final normalized score",
        type: 'number',
        defaultValue: function defaultValue() {
          return 0;
        }
      },
      normalizedMax: {
        name: "Normalized Max",
        description: "The highest score on the final normalized score",
        type: 'number',
        defaultValue: function defaultValue() {
          return 100;
        }
      },
      rawMin: {
        name: "Raw Min",
        description: "The lowest score on the raw score",
        type: 'number',
        defaultValue: function defaultValue() {
          return 0;
        }
      },
      rawMax: {
        name: "Raw Max",
        description: "The highest score on the raw score",
        type: 'number',
        defaultValue: function defaultValue() {
          return 5;
        }
      },
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 1;
        }
      }
    }
  }, {
    id: 'reversedSubsetSum-v1',
    name: 'Reversed Subset Sum',
    description: 'Sum items together, but some are reversed',
    template: '{\n      "score_class_name": "Scoring::IntegerReversedSubsetSumScorer",\n      "params": {\n        "normal_questions": {{{json normalQuestions}}},\n        "reversed_questions": {{{json reversedQuestions}}},\n        "reverse_pivot": {{reversePivot}}\n      }\n    }',
    parameters: {
      normalQuestions: {
        name: "Normally-scored questions",
        description: "The mnemonics of the questions that should be scored normally",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      reversedQuestions: {
        name: "Reverse-scored questions",
        description: "The mnemonics of the questions that should have their scores inverted",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      reversePivot: {
        name: "Reverse pivot",
        description: "The number from which to subtract the reverse scored questions. E.g. if you have a 1-5 scoring rubric, 6 will translate that into a 5-1 score",
        type: 'number',
        defaultValue: function defaultValue() {
          return 5;
        }
      }
    }
  }, {
    id: 'meanFromSubscales-v1',
    name: 'Mean of Subscales',
    description: 'Takes a simple mean of all the subscale scorers',
    template: '{\n      "score_class_name": "Scoring::MeanFromSubscalesScorer",\n      "input_domains": {{{json input_domains}}}\n    }',
    parameters: {
      input_domains: {
        name: "Input domains",
        description: "The fully qualified scales and domains to sum",
        type: 'array'
      }
    }
  }, {
    id: 'simplemax-v1',
    name: 'Simple Max',
    description: 'Take the integer maximum of any question. At least one question must be answered to be valid',
    template: '{\n      "score_class_name": "Scoring::IntegerMaxScorer"\n    }'
  }, {
    id: 'questionCount-v1',
    name: 'Answered Question Count',
    description: 'Scores the number of answered questions',
    template: '{\n      "score_class_name": "Scoring::QuestionCountScorer"\n    }'
  }, {
    id: 'categoricalMax-v1',
    name: 'Categorical Max from Subscales',
    description: 'Has no value, but takes the categorical maximum from the individual subscales',
    template: '{\n      "score_class_name": "Scoring::CategoricalMaxScorer",\n      "input_domains": {{{json input_domains}}}\n    }',
    parameters: {
      input_domains: {
        name: "Input domains",
        description: "The fully qualified scales and domains to sum",
        type: 'array'
      }
    }
  }, {
    id: 'percentage-v1',
    name: 'Percentage',
    description: 'Percentage of the supplied denominator',
    template: '{\n      "score_class_name": "Scoring::PercentageScorer",\n      "params": {\n        "denominator": {{withNull denominator}},\n        "denominator_question": {{{withNull denominatorQuestion}}},\n        "numerator": {{withNull numerator}},\n        "numerator_question": {{{withNull numeratorQuestion}}}\n      }\n    }',
    parameters: {
      numerator: {
        name: "Numerator",
        description: "The numerator of the percentage calculation",
        type: 'number'
      },
      numeratorQuestion: {
        name: "Numerator Question",
        description: "The variable name for the numerator of the percentage calculation",
        type: 'text'
      },
      denominator: {
        name: "Denominator",
        description: "The denominator of the percentage calculation",
        type: 'number'
      },
      denominatorQuestion: {
        name: "Denominator Question",
        description: "The variable name for the denominator of the percentage calculation",
        type: 'text'
      }
    }
  }, {
    id: 'percentageSum-v1',
    name: 'Percentage Sums',
    description: 'Percentage of the sums of the supplied numerator denominator',
    template: '{\n      "score_class_name": "Scoring::PercentageScorer",\n      "params": {\n        "denominator_questions": {{{withNull denominatorQuestions}}},\n        "numerator_questions": {{{withNull numeratorQuestions}}}\n      }\n    }',
    parameters: {
      numeratorQuestions: {
        name: "Numerator Questions",
        description: "The variable names for the numerator of the percentage calculation",
        type: 'array',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      denominatorQuestions: {
        name: "Denominator Questions",
        description: "The variable names for the denominator of the percentage calculation",
        type: 'array',
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }, {
    id: 'percentageSumFixed-v1',
    name: 'Percentage Sum of Denominator',
    description: 'Percentage of the sums of the supplied numerator ',
    template: '{\n      "score_class_name": "Scoring::PercentageScorer",\n      "params": {\n        "denominator": {{{withNull denominator}}},\n        "numerator_questions": {{{withNull numeratorQuestions}}}\n      }\n    }',
    parameters: {
      numeratorQuestions: {
        name: "Numerator Questions",
        description: "The variable names for the numerator of the percentage calculation",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      denominator: {
        name: "Denominator",
        description: "The denominator of the percentage calculation",
        type: 'number'
      }
    }
  }, {
    id: 'percentageSumOfAnswereds-v1',
    name: 'Percentage Sum of Number of Answered Questions',
    description: 'Percentage sum where only answered questions are counted',
    template: '{\n      "score_class_name": "Scoring::PercentageOfAnsweredScorer",\n      "params": {\n        "max_per_question": {{{withNull maxPerQuestion}}},\n        "questions": {{{withNull numeratorQuestions}}}\n      }\n    }',
    parameters: {
      numeratorQuestions: {
        name: "Questions",
        description: "The variable names for the items to be used",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      maxPerQuestion: {
        name: "Max Per Question",
        description: "For each answered question, how much should be added to the denominator",
        type: 'number'
      }
    }
  }, {
    id: 'countoverthreshold-v1',
    name: 'Number over threshold',
    description: 'Counts the number of answers greater than or equal to the threshold supplied.',
    template: '{\n      "score_class_name": "Scoring::SumOverThresholdScorer",\n      "params": {\n        "threshold": {{threshold}},\n        "missing_allowed": {{missingAllowed}}\n      }\n    }',
    parameters: {
      threshold: {
        name: "Threshold",
        description: "Scores greater than or equal to this will be counted. Scores beneath will not.",
        type: 'number',
        defaultValue: 1
      },
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 0;
        }
      }
    }
  }, {
    id: 'subsetcountoverthreshold-v1',
    name: 'Subset Number over threshold',
    description: 'Counts the number of answers in the set greater than or equal to the threshold supplied.',
    template: '{\n      "score_class_name": "Scoring::SubsetSumOverThresholdScorer",\n      "params": {\n        "threshold": {{threshold}},\n        "missing_allowed": {{missingAllowed}},\n        "scored_questions": {{{json scoredQuestions}}}\n      }\n    }',
    parameters: {
      scoredQuestions: {
        name: "Scored questions",
        description: "The mnemonics of the questions that should be scored",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      threshold: {
        name: "Threshold",
        description: "Scores greater than or equal to this will be counted. Scores beneath will not.",
        type: 'number',
        defaultValue: 1
      },
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 0;
        }
      }
    }
  }, {
    id: 'endorsedQuestionsCount-v1',
    name: 'Endored questions over threshold',
    description: 'Counts the number of QUESTIONS greater than or equal to the threshold supplied. Can be used for checkboxes etc where there can be more than one answer per question',
    template: '{\n      "score_class_name": "Scoring::EndorsedQuestionsScorer",\n      "params": {\n        "threshold": {{threshold}},\n        "missing_allowed": {{missingAllowed}}\n      }\n    }',
    parameters: {
      threshold: {
        name: "Threshold",
        description: "Scores greater than or equal to this will be counted. Scores beneath will not.",
        type: 'number',
        defaultValue: 1
      },
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 0;
        }
      }
    }
  }, {
    id: 'tscore-v1',
    name: 'T-Score',
    description: 'Uses norms with a tscore',
    template: '{\n      "score_class_name": "Scoring::TScorer",\n      "input_domains": ["{{input}}"]\n    }',
    parameters: {
      input: {
        name: "Input",
        description: "The computed raw value",
        type: 'text',
        defaultValue: 'raw'
      }
    }
  }, {
    id: 'smartNorm-v1',
    name: 'Smart Normed',
    description: 'Automatically calculate a norm on the basis of which norms are added',
    template: '{\n      "score_class_name": "Scoring::SmartNormedScorer",\n      "input_domains": ["{{input}}"]\n    }',
    parameters: {
      input: {
        name: "Input",
        description: "The computed raw value",
        type: 'text',
        defaultValue: 'raw'
      }
    }
  }, {
    id: 'compositeSum-v1',
    name: 'Composite Count',
    description: 'Add other previously computed scales to get a result',
    template: '{\n      "score_class_name": "Scoring::IntegerCompositeSumScorer",\n      "input_domains": {{{json input_domains}}}\n    }',
    parameters: {
      input_domains: {
        name: "Input domains",
        description: "The fully qualified scales and domains to sum",
        type: 'array'
      }
    }
  }, {
    id: 'compositeCountOverThreshold-v1',
    name: 'Composite Count Over Threshold',
    description: 'Counts all computed values over the given threshold',
    template: '{\n      "score_class_name": "Scoring::CompositeCountOverThresholdScorer",\n      "input_domains": {{{json input_domains}}},\n      "params": {\n        "threshold": {{{withNull threshold}}},\n        "threshold_mnemonic": {{{withNull thresholdMnemonic}}}\n      }\n    }',
    parameters: {
      input_domains: {
        name: "Input domains",
        description: "The fully qualified scales and domains to sum",
        type: 'array'
      },
      threshold: {
        name: "Threshold",
        description: "Scores greater than or equal to this will be counted. Scores beneath will not.",
        type: 'number',
        defaultValue: null
      },
      thresholdMnemonic: {
        name: "Threshold Mnemonic",
        description: "Scores with this threshold mnemonic will be counted",
        type: 'text',
        defaultValue: null
      }
    }
  }, {
    id: 'compositeMean-v1',
    name: 'Composite Mean',
    description: 'Average the results given to get a new result',
    template: '{\n      "score_class_name": "Scoring::CompositeMeanScorer",\n      "input_domains": {{{json input_domains}}}\n    }',
    parameters: {
      input_domains: {
        name: "Input domains",
        description: "The fully qualified scales and domains to average",
        type: 'array'
      }
    }
  }, {
    id: 'sumWithMissing-v1',
    name: 'Integer Sum With Missing',
    description: 'Add together all questions. You may miss up to a certain number of questions and they will be scored at the given score.',
    template: '{\n      "score_class_name": "Scoring::IntegerSumWithMissingScorer",\n      "params": {\n        "missing_value": {{missingValue}},\n        "missing_allowed": {{missingAllowed}}\n      }\n    }',
    parameters: {
      missingValue: {
        name: "Missing Value",
        description: "The value for missing questions",
        type: 'number',
        defaultValue: function defaultValue() {
          return 0;
        }
      },
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 1;
        }
      }
    }
  }, {
    id: 'sumWithUpscale-v1',
    name: 'Upscale Integer Sum',
    description: 'Add together all questions, and then upscale the answer prorated as long as no more than a maximum number of questions have been missed',
    template: '{\n      "score_class_name": "Scoring::IntegerUpscaleSum",\n      "params": {\n        "missing_allowed": {{missingAllowed}}\n      }\n    }',
    parameters: {
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 1;
        }
      }
    }
  }, {
    id: 'sumWithMedianUpscale-v1',
    name: 'Upscale (Median) Integer Sum',
    description: 'Add together all questions, and then upscale the answer prorated as long as no more than a maximum number of questions have been missed. Upscale is done via taking median of the answers',
    template: '{\n      "score_class_name": "Scoring::IntegerMedianUpscaleSum",\n      "params": {\n        "missing_allowed": {{missingAllowed}}\n      }\n    }',
    parameters: {
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 1;
        }
      }
    }
  }, {
    id: 'reversedSumWithUpscale-v1',
    name: 'Reversed Upscale Integer Sum',
    description: 'Add together all questions, some of which are reversed, and then upscale the answer prorated as long as no more than a maximum number of questions have been missed',
    template: '{\n      "score_class_name": "Scoring::IntegerReversedSubsetUpscaleSumScorer",\n      "params": {\n        "normal_questions": {{{json normalQuestions}}},\n        "reversed_questions": {{{json reversedQuestions}}},\n        "reverse_pivot": {{reversePivot}},\n        "missing_allowed": {{missingAllowed}}\n      }\n    }',
    parameters: {
      normalQuestions: {
        name: "Normally-scored questions",
        description: "The mnemonics of the questions that should be scored normally",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      reversedQuestions: {
        name: "Reverse-scored questions",
        description: "The mnemonics of the questions that should have their scores inverted",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      reversePivot: {
        name: "Reverse pivot",
        description: "The number from which to subtract the reverse scored questions. E.g. if you have a 1-5 scoring rubric, 6 will translate that into a 5-1 score",
        type: 'number',
        defaultValue: function defaultValue() {
          return 5;
        }
      },
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 1;
        }
      }
    }
  }, {
    id: 'subsetUpscaleSum-v1',
    name: 'Subset Upscale Integer Sum',
    description: 'Add together specified questions, and then upscale the answer prorated as long as no more than a maximum number of questions have been missed',
    template: '{\n      "score_class_name": "Scoring::IntegerSubsetUpscaleSumScorer",\n      "params": {\n        "scored_questions": {{{json scoredQuestions}}},\n        "missing_allowed": {{missingAllowed}}\n      }\n    }',
    parameters: {
      scoredQuestions: {
        name: "Scored questions",
        description: "The mnemonics of the questions that should be scored normally",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      missingAllowed: {
        name: "Max questions to miss",
        description: "The maximum number of questions you are allowed to miss before the result is invalid",
        type: 'number',
        defaultValue: function defaultValue() {
          return 1;
        }
      }
    }
  }, {
    id: 'screenerscorer-v1',
    name: 'Screener Sum Scorer',
    description: 'Add together all the specified questions. Valid if either just the screener questions were answered, or all questions',
    template: '{\n      "score_class_name": "Scoring::ScreenerSumScorer",\n      "params": {\n        "screener_questions": {{{json screenerQuestions}}},\n        "main_questions": {{{json mainQuestions}}}\n      }\n    }',
    parameters: {
      screenerQuestions: {
        name: "Screener questions",
        description: "The mnemonics of the questions that should be scored for screening purposes",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      mainQuestions: {
        name: "Main questions",
        description: "The mnemonics of the questions that should be scored if the full scale is triggered",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }, {
    id: 'multiLevelScreenerScorer-v1',
    name: 'Multi Level Screener Scorer',
    description: 'Can be screened into Acute (2), Non-Acute (1) or not positive (0) on yes/no questions',
    template: '{\n      "score_class_name": "Scoring::MultiLevelScreenerScorer",\n      "params": {\n        "nonAcuteQuestions": {{{json nonAcuteQuestions}}},\n        "acuteQuestions": {{{json acuteQuestions}}}\n      }\n    }',
    parameters: {
      acuteQuestions: {
        name: "Acute Questions",
        description: "The mnemonics of the questions that will trigger an acute score of 2",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      nonAcuteQuestions: {
        name: "Non-acute questions",
        description: "The mnemonics of the questions that will trigger a non-acute score of 1",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }, {
    id: 'disjointscreenerscorer-v1',
    name: 'Disjoint Screener Sum Scorer',
    description: 'Add together the main questions. Valid if either just the screener questions were answered, or all questions. Useful where there is a separate section triggered by questions, but you don\'t add the scores from the sections together',
    template: '{\n      "score_class_name": "Scoring::DisjointScreenerSumScorer",\n      "params": {\n        "screener_questions": {{{json screenerQuestions}}},\n        "main_questions": {{{json mainQuestions}}}\n      }\n    }',
    parameters: {
      screenerQuestions: {
        name: "Screener questions",
        description: "The mnemonics of the questions that should be scored for screening purposes",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      },
      mainQuestions: {
        name: "Main questions",
        description: "The mnemonics of the questions that should be scored if the full scale is triggered",
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }, {
    id: 'recentChange-v1',
    name: 'Recent Change Scorer',
    description: 'Translates the answers into data for the recent changes system',
    template: '{\n      "score_class_name": "Scoring::CustomRecentChangesScorer"\n    }'
  }, {
    id: 'aggregate-v1',
    name: 'Aggregate',
    description: 'Take the only result from another scale and copy it to here',
    template: '{\n      "score_class_name": "Scoring::AggregateScorer",\n      "input_domains": {{{json input_domains}}}\n    }',
    parameters: {
      input_domains: {
        name: "Input domains",
        description: "The fully qualified scales and domains to take from",
        type: 'array'
      }
    }
  }, {
    id: 'customClass-v1',
    name: 'Custom Class',
    description: 'Give a full class name to use a custom scorer',
    template: '{\n      "score_class_name": "{{customClass}}"\n    }',
    parameters: {
      customClass: {
        name: "Custom Class",
        description: "The full ruby class name of the scorer",
        type: "text"
      }
    }
  }, {
    id: 'customClassWithParams-v1',
    name: 'Custom Class With Parameters',
    description: 'Give a full class name to use a custom scorer with extra parameters',
    template: '{\n      "score_class_name": "{{customClass}}",\n      "params" : {{{json customParams}}},\n      "input_domains" : {{{json inputDomains}}}\n    }',
    parameters: {
      customClass: {
        name: "Custom Class",
        description: "The full ruby class name of the scorer",
        type: "text"
      },
      customParams: {
        name: "Parameters",
        description: "A JSON blob of parameters to be passed to the scorer",
        type: "json"
      },
      inputDomains: {
        name: "Input domains",
        description: "Other computed values to be used for scoring",
        type: "array",
        defaultValue: function defaultValue() {
          return [];
        }
      }
    }
  }];

  var normTemplates = [{
    id: 'stddev-v1',
    name: 'Norm with mean/stddev',
    description: 'Norm with supplied mean and stddev',
    template: '{\n      "mean": {{mean}},\n      "stddev": {{stddev}},\n      "mode": "stddev"\n    }',
    parameters: {
      mean: {
        name: "Mean",
        description: "The population mean",
        type: 'number',
        defaultValue: 0
      },
      stddev: {
        name: "Stddev",
        description: "The population standard deviation",
        type: 'number',
        defaultValue: 0
      }
    }
  }, {
    id: 'tscore-v1',
    name: 'Norm with tscore lookup',
    description: 'Norm with supplied tscore table',
    template: '{\n      "t_score_table": {{{json tscoreTable}}},\n      "mode": "tscore"\n    }',
    parameters: {
      tscoreTable: {
        name: "T-scores",
        description: "T score lookup",
        type: "subList",
        defaultValue: function defaultValue() {
          return [];
        },

        columns: [{
          property: "min_raw",
          title: ">=",
          type: 'number',
          size: 4
        }, {
          property: "max_raw",
          title: "<=",
          type: 'number',
          size: 4
        }, {
          property: "raw",
          title: "==",
          type: 'number',
          size: 4
        }, {
          property: "value",
          title: "Result",
          type: "number",
          size: 4
        }]
      }
    }
  }, {
    id: 'percentile-v1',
    name: 'Norm with percentile lookup',
    description: 'Norm with supplied percentile table',
    template: '{\n      "t_score_table": {{{json tscoreTable}}},\n      "mode": "percentile"\n    }',
    parameters: {
      tscoreTable: {
        name: "Percentiles",
        description: "Lookup table for percentile",
        type: "subList",
        defaultValue: function defaultValue() {
          return [];
        },

        columns: [{
          property: "raw",
          title: "==",
          type: 'number',
          size: 4
        }, {
          property: "value",
          title: "Result",
          type: "number",
          size: 4
        }]
      }
    }
  }];

  var scaleTemplates = [{
    id: 'scale-v1',
    name: 'Scale',
    description: 'A scale',
    writeContent: function writeContent() {
      return {};
    }
  }];

  var subscaleTemplates = [{
    id: 'subscale-v1',
    name: 'Subscale',
    description: 'A subscale',
    writeContent: function writeContent() {
      return {};
    }
  }];

  var scaleGroupTemplates = [{
    id: 'scaleGroup-v1',
    name: 'Scale Group',
    description: 'A scale group',
    writeContent: function writeContent() {
      return {};
    }
  }];

  var questionConstructTemplates = [{
    id: 'questionConstruct-v1',
    name: 'Question Construct',
    description: 'A question construct',
    writeContent: function writeContent() {
      return {};
    }
  }];

  var templates = {
    survey: surveyTemplates,
    page: pageTemplates,
    question: questionTemplates,
    scorer: scorerTemplates,
    norm: normTemplates,
    scale: scaleTemplates,
    subscale: subscaleTemplates,
    scaleGroup: scaleGroupTemplates,
    questionConstruct: questionConstructTemplates
  };

  var templatesById = {};

  var _iteratorNormalCompletion2 = true;
  var _didIteratorError2 = false;
  var _iteratorError2 = undefined;

  try {
    for (var _iterator2 = Object.entries(templates)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
      var _ref3 = _step2.value;

      var _ref4 = _slicedToArray(_ref3, 2);

      var key = _ref4[0];
      var value = _ref4[1];

      templatesById[key] = value.reduce(function (obj, template) {
        obj[template.id] = template;return obj;
      }, {});
    }
  } catch (err) {
    _didIteratorError2 = true;
    _iteratorError2 = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion2 && _iterator2.return) {
        _iterator2.return();
      }
    } finally {
      if (_didIteratorError2) {
        throw _iteratorError2;
      }
    }
  }

  exports.default = Ember.Service.extend({
    templates: templates,
    templatesById: templatesById,

    register: function register(domain, template) {
      this.get('templates')[template.id] = template;
    },
    lookup: function lookup(domain, id) {
      return this.get('templatesById')[domain][id];
    }
  });
});