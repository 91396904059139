define('frontend/pods/components/provider/create-demo-user-form/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var childScaleSets = ['sfss', 'vanderbilt3'];
  var teacherScaleSets = ['vanderbilt3'];

  exports.default = Ember.Component.extend(_errorPrinter.default, {
    scaleSets: [{
      id: 'cams',
      name: 'Basic CAMS'
    }, {
      id: 'primaryCare',
      name: 'Primary Care Screener (PHQ/SBIRT)'
    }, {
      id: 'depressionScreener',
      name: 'Depression Screener'
    }, {
      id: 'suds',
      name: 'SUD Screener'
    }, {
      id: 'bam',
      name: 'BAM'
    }, {
      id: 'dast10',
      name: 'DAST10'
    }, {
      id: 'ohio',
      name: 'Ohio (Child)'
    }, {
      id: 'sfss',
      name: 'SFSS'
    }, {
      id: 'vanderbilt3',
      name: 'Vanderbilt v3'
    }, {
      id: 'decisionSupport',
      name: 'SDOH Decision Support'
    }],
    child: Ember.computed('model.scaleSet', function () {
      return childScaleSets.includes(this.get("model.scaleSet"));
    }),
    teacher: Ember.computed('model.scaleSet', function () {
      return teacherScaleSets.includes(this.get("model.scaleSet"));
    }),
    messages: Ember.inject.service(),
    actions: {
      submit: function submit() {
        var _this = this;

        var request = this.get("model");

        request.set("child", this.get("child"));

        var onSuccess = function onSuccess(result) {
          _this.get("router").transitionTo("provider.patients.show.sessions.show", result.get("patient.id"), result.get("patientSession.id"));
        };

        var onFailure = function onFailure(error) {
          _this.get('messages').danger(_this.printError(error));
        };

        return request.save().then(onSuccess, onFailure);
      }
    }
  });
});